.filter-count {
  padding-right: ($spacer * .25) !important;
  padding-left: ($spacer * .25) !important;
  margin-bottom: ($spacer * .25);
  color: $text-muted;
  font-size: 0.9rem;
}

.filter-title {
  padding-right: ($spacer * .25) !important;
  font-size: 0.9rem;
  color: $text-muted;
}