body {
    height: 100%;
    letter-spacing: 0.1px;
}

.app-body {
    overflow-x: unset;
}

.app-header.bg-light .navbar-brand {
    opacity: 1;
}

.global-search .input-group {
    width: 40rem!important;
}

h4 {
    margin-bottom: 1rem;
    color: $gray-700;
    font-weight: 400;
}

// Icons Put space after an icon
a i {
    padding-right: 0.4rem;
}

// Reduce nav bar icon size
.sidebar.sidebar-pills .nav-link .nav-icon {
    font-size: 1rem;
}

th {
    font-size: 0.8rem;
    font-weight: 600;
    color: $gray-600;
    text-transform: uppercase;
}

.card-header h1,
.card-header h2,
.card-header h3,
.card-header h4 {
    display: inline;
}

/*.card-body {
    padding-top: 0.8rem;
}*/

.card-table {
    padding: 0!important;
}

.nav-link i {
    padding-right: 0.5rem;
    font-size: 0.8rem;
}

.btn i {
    padding-right: 0.4rem;
}

.nav-tabs .nav-link:hover {
    border-bottom-width: 0;
}

.actions {
    white-space: nowrap !important;
    text-align: right;
}

.statuslist {
    font-size: $font-size-sm;
}

/* One radio button per line */
.form-check label {
    display: block;
    cursor: pointer;
    line-height: 1.5;
    //font-size: 1.5em;
}

.form-check-input {
    position: relative;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.checkbox-tree ul {
    list-style-type: none;
}

.checkbox-tree .custom-checkbox {
    margin: 0.5rem;
}

.checkbox-tree label {
    padding: 0.2em;
}

.checkbox-tree :checked + label {
    background-color: #fff6bf;
    //padding: 0.2em;
}

.form-text {
    //margin-left: 1rem;
    font-size: $font-size-sm;
}

// Always show invalid feedback by default
.invalid-feedback {
    display: block;
    margin-left: 1rem;
}

legend {
    border-bottom: solid 1px $secondary;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    text-transform: uppercase;
}

/*.form-group {
    margin-bottom: 1.5em;
}*/

/*fieldset div {
    margin-left: 1rem;
    margin-right: 1rem;
}*/

.alert-secondary {
    color: $dark;
}

.text-count-wrapper {
    color: $text-muted;
    font-weight: bold;
}

/**
 * SEO Snippet
 */

#seosnippet {
    font-family: arial,sans-serif;
    font-size: 13px !important;
    font-style: normal;
    line-height: 15px !important;
    margin: 10px 0;
    max-width: 50%;
    padding: 10px;
    width: auto;
    border-style: inset;
    border-color: #ccc;
    border-width: 1px;
}

#seosnippet p {
    margin: 0.5em 0;
}

#seosnippet .seotitle {
    color: #1111CC;
    font-size: 16px !important;
    line-height: 19px;
    text-decoration: underline;
}

#seosnippet .seourl {
    color: #228822;
    font-size: 13px;
    line-height: 15px;
}

#seosnippet .seodesc {
    color: #000000;
    font-size: 13px;
    line-height: 15px;
}

.select2.select2-container {
    display: block !important;
}

.form-check input {
    margin-right: 0.8em;
}