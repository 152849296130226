/**
* Colour Block Classes
*/
.attribute-colour {
  white-space: nowrap;
  display: inline;
}

.attribute-colour__block {
  width: 20px;
  height: 20px;
  //float: left;
  border: 1px solid #d1d1d1;
  margin-right: 6px;
}

.attribute-colour__multi {
  width: 50%;
  height: 100%;
  display: inline-block;
}