$h1-font-size:                $font-size-base * 1.8 !default;
$h2-font-size:                $font-size-base * 1.4 !default;
$h3-font-size:                $font-size-base * 1.4 !default;
$h4-font-size:                $font-size-base * 1.20 !default;
$h5-font-size:                $font-size-base * 1.20 !default;
$h6-font-size:                $font-size-base !default;

$pagination-padding-y:              .4rem !default;
$pagination-padding-x:              .75rem !default;

$badge-font-weight: 550;

// Overide electric purple hover
$hover-color: rgba(0, 0, 0, 0.02);