:not(.lt-ie9) {

  .button-selector {
    border: 0;
    margin-bottom: 0.2em;
  }

  /*.button-selector li {
    margin-bottom: 0.2em;
  }*/

  /* HTML5 Boilerplate accessible hidden styles */
  .button-selector input[type="radio"] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px; margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .button-selector input[type="radio"] + span {
    display: block;
  }

  .button-selector input[type="radio"] + span {
    outline: none;
    //background: none;
    text-align: left;
    font-size: inherit;
    font-weight: inherit;
    margin-bottom: 0;
    border: 1px solid $gray-200;
    //background-color: $gray-100;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    color: $gray-900;
    display: block;
    padding: 0.6em 0.8em;
    position: relative;
    text-decoration: none;
    width: 100%;
  }

  .button-selector input[type="radio"] + span.disabled {
    opacity: .5;
    background: -webkit-linear-gradient(164deg,#f3f3f3,#f3f3f3 49.6%,#7f7f7f 50%,#f3f3f3 50.4%,#f3f3f3);
    background: -moz-linear-gradient(164deg,#f3f3f3 0,#f3f3f3 49.6%,#7f7f7f 50%,#f3f3f3 50.4%,#f3f3f3 100%);
    background: linear-gradient(164deg,#f3f3f3,#f3f3f3 49.6%,#7f7f7f 50%,#f3f3f3 50.4%,#f3f3f3);
  }

  .button-selector input[type="radio"]:checked + span {
    border-color: $gray-900;
    //font-weight: $font-weight-bold - 100;
    color: $gray-900;
    background-color: $gray-200;
  }

  /* the checked style using the :checked pseudo class */
  /*[type="radio"]:checked + span::before {
    background: green;
    box-shadow: 0 0 0 0.25em #000;
  }*/

  /* never forget focus styling */
  .button-selector input[type="radio"]:focus + span {
    border-color: $gray-900;
    //font-weight: $font-weight-bold - 100;
    color: $gray-900;
  }

  .button-selector input[type="radio"]:hover + span {
    border-color: $gray-900;
    //font-weight: $font-weight-bold - 100;
    color: $gray-900;
    cursor: pointer;
  }
}