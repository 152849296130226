.product-grid__card {
  border: 1px solid $gray-200;
  //font-size: 95%;
  letter-spacing: 0.05em;
}

.product-grid__image {
  position: relative;
}

.product-promotion {
  display: block;
  position: absolute;
  text-transform: uppercase;
  width: auto;
  max-width: 100%;
  left: 0;
  bottom: 0;
  right: auto;
  top: auto;
  text-align: left;
  color: $white;
  font-size: 70%;
  font-weight: bold;
  letter-spacing: 0.09em;
  padding: 0.8em 1em;
  background-color: rgba(0,23,79,.8);
  //z-index: 3;
  margin: 0 0 8px 8px;
}

.product-grid__tag {
  color: #00af3f;
  text-transform: uppercase;
  font-size: 80%;
  font-weight: 500;
  //font-family: Futura,Calibri,sans-serif;
  //letter-spacing: .5px;
  //line-height: 14px;
  display: block;
  @include my(1);
}

// PAdding for empty tags to make sure title line up
.product-grid__tag:empty {
  padding: 0.7rem;
}

.product-grid__tag--gift {
  border-top: #00af3f 5px solid;
}