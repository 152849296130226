.widget {
  border: 1px solid $gray-200;
  letter-spacing: 0.05em;
  padding: 1em;
  border-left-width: 0.3rem;
}

@each $color, $value in $colors {
  .widget-#{$color} {
    border-left-color: lighten($value, 30%);
  }
}

.table-widget {
  font-size: 95%;
}